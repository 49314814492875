import {
  DELETE_SCENARIO_REQUEST,
  DELETE_SCENARIO_SUCCESS,
  DELETE_SCENARIO_ERROR,
  GET_SCHEDULE_ACTIVITIES,
  GET_CHANCE_OF_SUCCESS_REQUEST,
  GET_CHANCE_OF_SUCCESS_SUCCESS,
  GET_CHANCE_OF_SUCCESS_ERROR,
  GET_SCENARIOS_REQUEST,
  GET_SCENARIOS_SUCCESS,
  GET_SCENARIOS_SUCCESS_AND_SET_ACTIVE_SCENARIO,
  GET_SCENARIOS_ERROR,
  GET_SCENARIO_REQUEST,
  GET_SCENARIO_SUCCESS,
  GET_SCENARIO_ERROR,
  GENERATE_SCHEDULE_REQUEST,
  GENERATE_SCHEDULE_SUCCESS,
  GENERATE_SCHEDULE_ERROR,
  UPDATE_SCENARIO_REQUEST,
  UPDATE_SCENARIO_SUCCESS,
  UPDATE_SCENARIO_ERROR,
  CLEAR_CURRENT_SCENARIO,
  GET_SCENARIO_METRIC_REQUEST,
  GET_SCENARIO_METRIC_SUCCESS,
  GET_SCENARIO_METRIC_ERROR,
  SET_CURRENT_SCENARIO,
  UPDATE_SCENARIOS_ERROR,
  UPDATE_SCENARIOS_REQUEST,
  UPDATE_SCENARIOS_SUCCESS,
  COPY_SCENARIO_REQUEST,
  COPY_SCENARIO_SUCCESS,
  COPY_SCENARIO_ERROR,
  CREATE_SCENARIO_REQUEST,
  CREATE_SCENARIO_SUCCESS,
  CREATE_SCENARIO_ERROR,
  UPDATE_CURRENT_SCENARIO_REQUEST,
  UPDATE_CURRENT_SCENARIO_SUCCESS,
  UPDATE_CURRENT_SCENARIO_ERROR,
  UPDATE_SCENARIO_STATE,
  MARK_FEVER_CHART_SUCCESS,
  GET_ALL_APPROVED_SCENARIOS_REQUEST,
  GET_ALL_APPROVED_SCENARIOS_SUCCESS,
  GET_ALL_APPROVED_SCENARIOS_ERROR,
  CONVERT_TO_AUTOMATIC_REQUEST,
  CONVERT_TO_AUTOMATIC_SUCCESS,
  CONVERT_TO_AUTOMATIC_ERROR,
  GET_SWAP_SCENARIOS_REQUEST,
  GET_SWAP_SCENARIOS_SUCCESS,
  GET_SWAP_SCENARIOS_ERROR,
  GET_EVM_REQUEST,
  GET_EVM_SUCCESS,
  GET_EVM_ERROR,
  UPDATE_DOR_WITH_CONFORM_PROGRESS_SUCCESS,
  GET_SUB_CODES_OF_PROJECT,
  GET_PORTFOLIO_SCENARIOS_REQUEST,
  GET_PORTFOLIO_SCENARIOS_SUCCESS,
  GET_PORTFOLIO_SCENARIOS_ERROR,
  DELETE_PORTFOLIO_SCENARIO_ERROR,
  DELETE_PORTFOLIO_SCENARIO_SUCCESS,
  DELETE_PORTFOLIO_SCENARIO_REQUEST,
  ADD_PORTFOLIO_SCENARIO,
  UPDATE_PORTFOLIO_SCENARIO_REQUEST,
  UPDATE_PORTFOLIO_SCENARIO_SUCCESS,
  UPDATE_PORTFOLIO_SCENARIO_ERROR,
  APPROVE_PORTFOLIO_SCENARIO,
  SET_PORTFOLIO_SCENARIO_AS_SIMULATED,
  REJECT_PORTFOLIO_SCENARIO,
  GET_PORTFOLIO_SCENARIO_NAME,
  RESET_ASSUMPTIONS,
  SET_ACTIVE_SCENARIO,
} from "../types";
import { AUTOMATIC_PC, SYNC, UNSYNC } from "../../global/constants";
import produce from "immer";
import { updateItemInArray } from "../../utils/FilterUtils/FilterUtils";

const initialState = {
  items: [],
  item: {},
  isUpdating: false,
  isCopying: false,
  scheduleActivities: [],
  approvedScenarios: [],
  portfolioScenarios: [],
  swapScenarios: [],
  subCodes: [],
  isFetching: false,
  error: "",
};

const initialState2 = {
  scenarioMetric: {},
  currentPortfolioScenarioName: "",
  evmData: {},
  chanceOfSuccess: 100, // TODO
  isMetricsFetching: false,
  isChanceFetching: false,
  isEVMFetching: false,
  error: "",
};

// eslint-disable-next-line
export function scenarioReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.items = [];
      });
    case CREATE_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.items = [...draftState.items, payload];
        draftState.item = payload;
      });
    case CREATE_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case COPY_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isCopying = true;
        draftState.error = "";
      });
    case COPY_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isCopying = false;
        draftState.items = [...draftState.items, payload];
      });
    case COPY_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isCopying = false;
        draftState.error = payload;
      });
    case UPDATE_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
        draftState.error = "";
      });
    case UPDATE_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.items = updateItemInArray(draftState.items, payload);
      });
    case UPDATE_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case UPDATE_SCENARIOS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
        draftState.error = "";
      });
    case UPDATE_SCENARIOS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.items = [...draftState.items].map(
          (item) => payload.find((p) => p.id === item.id) || item
        );
      });
    case UPDATE_SCENARIOS_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case GENERATE_SCHEDULE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GENERATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GENERATE_SCHEDULE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case GET_SCENARIOS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.items = [];
        draftState.item = {};
        draftState.error = "";
      });
    case GET_SCENARIOS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.items = payload;
      });
    case GET_SCENARIOS_SUCCESS_AND_SET_ACTIVE_SCENARIO:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.items = payload.items;

        if (payload.currentScenarioId) {
          draftState.item =
            payload.items.find((s) => s.id === +payload.currentScenarioId) ||
            {};
        }
      });
    case GET_SCENARIOS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case GET_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.item = {};
        draftState.error = "";
      });
    case GET_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.item = payload;
      });
    case GET_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case DELETE_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case DELETE_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.items = [...state.items].filter(
          (item) => item.id !== payload
        );
      });
    case DELETE_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case GET_SCHEDULE_ACTIVITIES:
      return {
        ...state,
        scheduleActivities: payload,
      };
    case UPDATE_CURRENT_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
        draftState.error = "";
      });
    case UPDATE_CURRENT_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.item = payload;
        draftState.items = updateItemInArray(draftState.items, payload);
      });
    case UPDATE_DOR_WITH_CONFORM_PROGRESS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.item.dateOfReview = payload.dateOfReview;
        const scenarioItem = {
          id: payload.scenarioId,
          dateOfReview: payload.dateOfReview,
        };
        draftState.items = updateItemInArray(draftState.items, scenarioItem);
      });
    case UPDATE_CURRENT_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case CLEAR_CURRENT_SCENARIO:
      return {
        ...state,
        item: {},
      };
    case SET_CURRENT_SCENARIO:
      return {
        ...state,
        item: payload,
      };
    case UPDATE_SCENARIO_STATE:
      return produce(state, (draftState) => {
        draftState.item.structure.state = payload ? SYNC : UNSYNC;
      });
    case MARK_FEVER_CHART_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case GET_ALL_APPROVED_SCENARIOS_REQUEST:
      return {
        ...state,
        isFetching: true,
        approvedScenarios: payload,
      };
    case GET_ALL_APPROVED_SCENARIOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        approvedScenarios: payload,
      };

    case GET_ALL_APPROVED_SCENARIOS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case CONVERT_TO_AUTOMATIC_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case CONVERT_TO_AUTOMATIC_SUCCESS:
      return produce(state, (draftState) => {
        draftState.item.automatic = true;
        draftState.item.fullyManual = false;
        draftState.isUpdating = false;
      });
    case CONVERT_TO_AUTOMATIC_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case GET_SWAP_SCENARIOS_REQUEST:
      return produce(state, (draftState) => {
        draftState.error = "";
      });
    case GET_SWAP_SCENARIOS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.swapScenarios = payload || [];
      });
    case GET_SWAP_SCENARIOS_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload;
      });
    case GET_SUB_CODES_OF_PROJECT:
      return produce(state, (draftState) => {
        draftState.subCodes = payload;
      });
    case ADD_PORTFOLIO_SCENARIO:
      return produce(state, (draftState) => {
        const newPortfolioScenarios = draftState.portfolioScenarios.slice();
        newPortfolioScenarios.push(payload);
        draftState.portfolioScenarios = newPortfolioScenarios;
      });
    case GET_PORTFOLIO_SCENARIOS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
      });
    case GET_PORTFOLIO_SCENARIOS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.portfolioScenarios = payload;
      });
    case GET_PORTFOLIO_SCENARIOS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case DELETE_PORTFOLIO_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case DELETE_PORTFOLIO_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.portfolioScenarios = draftState.portfolioScenarios.filter(
          (scenario) => scenario.id !== payload
        );
      });
    case DELETE_PORTFOLIO_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case UPDATE_PORTFOLIO_SCENARIO_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case UPDATE_PORTFOLIO_SCENARIO_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.portfolioScenarios = draftState.portfolioScenarios.map(
          (scenario) => {
            if (scenario.id === payload.id) {
              scenario.name = payload.name;
              scenario.description = payload.description;
            }
            return scenario;
          }
        );
      });
    case UPDATE_PORTFOLIO_SCENARIO_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case APPROVE_PORTFOLIO_SCENARIO:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.portfolioScenarios = draftState.portfolioScenarios.map(
          (scenario) => {
            if (scenario.id === payload) scenario.approved = true;
            else scenario.approved = false;

            return scenario;
          }
        );
      });
    case REJECT_PORTFOLIO_SCENARIO:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.portfolioScenarios = draftState.portfolioScenarios.map(
          (scenario) => {
            if (scenario.id === payload) scenario.approved = false;

            return scenario;
          }
        );
      });
    case SET_PORTFOLIO_SCENARIO_AS_SIMULATED:
      return produce(state, (draftState) => {
        draftState.portfolioScenarios = draftState.portfolioScenarios.map(
          (scenario) => {
            if (scenario.id === payload) scenario.simulated = true;
            return scenario;
          }
        );
      });
    case RESET_ASSUMPTIONS:
      return produce(state, (draftState) => {
        draftState.item = {
          ...draftState.item,
          resourceBottleneck: {},
          resourceCapacityType: {},
          resourceSimulationInterval: {},
          resourceVariance: {},
        };
      });
    case SET_ACTIVE_SCENARIO:
      return produce(state, (draftState) => {
        draftState.item =
          draftState.items.find((item) => item.id === payload) || {};
      });
    default:
      return state;
  }
}

export function scenarioMetricsReducer(
  state = initialState2,
  { type, payload }
) {
  switch (type) {
    case GET_SCENARIO_METRIC_REQUEST:
      return {
        ...state,
        isMetricsFetching: true,
        error: "",
      };
    case GET_SCENARIO_METRIC_SUCCESS:
      return {
        ...state,
        scenarioMetric: payload,
        isMetricsFetching: false,
      };
    case GET_SCENARIO_METRIC_ERROR:
      return {
        ...state,
        isMetricsFetching: false,
        error: payload,
      };
    case GET_CHANCE_OF_SUCCESS_REQUEST:
      return {
        ...state,
        isChanceFetching: true,
      };
    case GET_CHANCE_OF_SUCCESS_SUCCESS:
      return {
        ...state,
        isChanceFetching: false,
        chanceOfSuccess: payload,
      };
    case GET_CHANCE_OF_SUCCESS_ERROR:
      return {
        ...state,
        isChanceFetching: false,
        error: payload,
      };
    case GET_EVM_REQUEST:
      return {
        ...state,
        isEVMFetching: true,
      };
    case GET_EVM_SUCCESS:
      return {
        ...state,
        isEVMFetching: false,
        evmData: payload,
      };
    case GET_EVM_ERROR:
      return {
        ...state,
        isEVMFetching: false,
        error: payload,
      };
    case GET_PORTFOLIO_SCENARIO_NAME:
      return {
        ...state,
        currentPortfolioScenarioName: payload,
      };
    default:
      return state;
  }
}
